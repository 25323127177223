import React from 'react';
import Img, { FluidObject } from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import Form from '../../Form';
import styled from 'styled-components';
import { IntroGrid } from '../../../pages/product';
import Container from '../../Container';
import Points from '../../Points';
import Marquee from '../../Marquee';
import { points } from '../../../constants/points';
import { customers, partners } from '../../../constants';

interface Props {
  ctaText: string;
  noMargin?: boolean;
}

interface Data {
  file: {
    childImageSharp: {
      fluid: FluidObject;
    };
  };
}

export default ({ ctaText, noMargin }: Props) => {
  const data = useStaticQuery<Data>(graphql`
    query SolutionsForm {
      file(relativePath: { eq: "ui/scorecard.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <FormWrapper noMargin={noMargin}>
      <Container>
        <IntroGrid>
          <Cta>
            <CtaText>{ctaText}</CtaText>
            <ImgWrapper>
              <Img fluid={data?.file?.childImageSharp?.fluid} />
            </ImgWrapper>
          </Cta>
          <FromInner>
            <Form
              whitefields
              formName="start-a-demo"
              successMessage="Thank you, we will be in touch with you soon"
              errorMessage="Oops, something went wrong - please try again"
            />
            <Keypoints>
              <Points points={points} quantity={3} isGreen />
            </Keypoints>
          </FromInner>
        </IntroGrid>
        <MarqueeWrapper>
          <Marquee transparent list={[...partners, ...customers]} title="Trusted by Our Customers and Partners" />
        </MarqueeWrapper>
      </Container>
    </FormWrapper>
  );
};

interface FormWrapperProps {
  noMargin?: boolean;
}

const MarqueeWrapper = styled.div`
  margin-bottom: 3rem;
`;

const FormWrapper = styled.section<FormWrapperProps>`
  background: ${({ theme }) => theme.colors.grey200};
  border-top: 1px solid ${({ theme }) => theme.colors.grey300};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey300};
  margin: ${({ noMargin }) => (noMargin ? '0' : '6rem 0')};

  ${IntroGrid} {
    padding-bottom: 1.5rem;
  }
`;

const FromInner = styled.div`
  padding: 3rem 1.5rem;
`;

const CtaText = styled.h4`
  font-weight: 600;
  font-size: 2rem;
  line-height: 1.4;
  margin: 0;
  color: ${({ theme }) => theme.colors.purple};
  text-transform: capitalize;
  position: relative;
  padding-bottom: 1.5rem;

  @media all and (max-width: 26.563em) {
    font-size: 1.5rem;
  }

  &:before {
    content: '';
    position: absolute;
    top: -3rem;
    background: ${({ theme }) => theme.colors.purple};
    left: 0;
    width: 100%;
    height: 4px;
  }
`;

const ImgWrapper = styled.div`
  box-shadow: ${({ theme }) => theme.boxShadow.medium};
`;

const Cta = styled.article`
  padding: 3rem 1.5rem;
  @media all and (max-width: 26.563em) {
    padding: 3rem 0 0;
  }
`;

const Keypoints = styled.div`
  display: grid;
  gap: 1.5rem;
  padding: 3rem 0;
`;
