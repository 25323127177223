import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

export default ({ children }: PropsWithChildren<{}>) => <H3>{children}</H3>;

const H3 = styled.h3`
  margin: 0;
  font-weight: 500;
  font-size: 2.25rem;
  letter-spacing: -1px;
  line-height: 1.3;
  text-transform: capitalize;
  position: relative;
  padding-top: 1.5rem;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    background: ${({ theme }) => theme.colors.purple};
    height: 4px;
    width: 3rem;
  }

  @media all and (max-width: 28.125em) {
    font-size: 1.5rem;
  }
`;
