import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

export default ({ children }: PropsWithChildren<{}>) => <H1>{children}</H1>;

const H1 = styled.h1`
  margin: 0;
  font-weight: 500;
  font-size: 3rem;
  letter-spacing: -1.5px;
  line-height: 1.1;

  @media all and (max-width: 48em) {
    font-size: 2rem;
  }
`;
