import React from 'react'
import { graphql, PageProps } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import styled from 'styled-components'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { SeoObject, Site } from '../components/Site'
import Footer from '../components/Footer/Footer'
import { IntroGrid } from '../pages/product'
import { Main as MainWrapper } from '../components/Styled'

import Intro from '../components/Intro'
import H1 from '../components/Solutions/utils/H1'
import H2 from '../components/Solutions/utils/H2'
import H3 from '../components/Solutions/utils/H3'
import CopyWrapper from '../components/Solutions/utils/CopyWrapper'
import HeroImage from '../components/BlogPosts/utils/HeroImage'
import Hr from '../components/BlogPosts/utils/Hr'
import CTA from '../components/BlogPosts/utils/CTA'
import ReadingTime from '../components/Solutions/utils/ReadingTime'
import ShareLinks from '../components/ShareLinks'
import Container from '../components/Container'
import P from '../components/Solutions/utils/P'
import Strong from '../components/Solutions/utils/Strong'
import Main from '../components/Solutions/utils/Main'
import Citation from '../components/Solutions/utils/Citation'
import StyledLink from '../components/Solutions/utils/StyledLink'
import Citations from '../components/Solutions/utils/Citations'
import Form from '../components/Solutions/utils/Form'
import ImageWrapper from '../components/Solutions/utils/ImageWrapper'
import HeroCta from '../components/Solutions/utils/HeroCta'
import HeroCtaImg from '../components/Solutions/utils/HeroCtaImg'
import QuoteBubble from '../components/QuoteBubble'
import Points from '../components/Points'

interface Frontmatter {
  title: string
  subtitle?: string
  metaDescription?: string
  metaTitle?: string
  display: string
  featureImg: {
    childImageSharp: {
      fluid: FluidObject
    }
    extension: string
    publicURL: string
  }
}

interface Props {
  data: {
    solution: {
      frontmatter: Frontmatter
      body: string
      fields: {
        readingTime: {
          text: string
        }
      }
    }
    images: {
      edges: {
        node: {
          fluid: FluidObject
        }
      }[]
    }
  }
}

const shortcodes = {
  IntroGrid,
  CopyWrapper,
  Intro,
  H1,
  H2,
  H3,
  HeroImage,
  ReadingTime,
  ShareLinks,
  Container,
  P,
  Strong,
  StyledLink,
  Hr,
  CTA,
  Main,
  Citation,
  Citations,
  Img,
  Form,
  ImageWrapper,
  HeroCta,
  HeroCtaImg,
  QuoteBubble,
  Points
}

export default ({ data: { solution, images }, ...props }: Props & PageProps) => {
  const seo: SeoObject = {
    title: solution.frontmatter?.metaTitle ?? solution.frontmatter.title,
    image: solution.frontmatter.featureImg.publicURL,
    description: solution.frontmatter?.metaDescription || solution.frontmatter.subtitle
  }

  const mapped = images.edges.map(({ node: { fluid } }) => fluid)

  return (
    <Site seo={seo} {...props}>
      <MainWrapper>
        <MDXProvider components={{ ...shortcodes }}>
          <BlogWrapper>
            <MDXRenderer
              images={mapped}
              readingTime={solution.fields.readingTime.text}
              frontmatter={solution.frontmatter}
            >
              {solution.body}
            </MDXRenderer>
          </BlogWrapper>
        </MDXProvider>
      </MainWrapper>
      <Footer />
    </Site>
  )
}

const BlogWrapper = styled.div`
  position: relative;
  z-index: 5;
`

export const pageQuery = graphql`
  query MdxSolutions($uid: String!, $regex: String!) {
    solution: mdx(frontmatter: { slug: { eq: $uid } }) {
      frontmatter {
        id
        title
        subtitle
        display
        date
        slug
        metaDescription
        metaTitle
        featureImg {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          extension
          publicURL
        }
      }
      body
      fields {
        readingTime {
          text
        }
      }
    }
    images: allImageSharp(
      filter: { fluid: { originalName: { regex: $regex } } }
      sort: { fields: fluid___originalName, order: ASC }
    ) {
      edges {
        node {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`
