import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

interface Props {
  fullWidth?: boolean;
}

export default ({ children, fullWidth }: PropsWithChildren<Props>) => (
  <CopyWrapper fullWidth={fullWidth}>{children}</CopyWrapper>
);

const CopyWrapper = styled.div<Props>`
  display: grid;
  gap: 1.5rem;
  grid-template-rows: min-content;
  padding: 0 1.5rem;
  grid-column: ${({ fullWidth }) => fullWidth && '-1/1'};
  font-size: 1.125rem;
  line-height: 1.8;

  a {
    text-decoration: underline;
    color: #4c2682;
  }

  @media all and (max-width: 48em) {
    padding: 0;
    grid-row: 1;
  }

  && > .bullets {
    list-style-type: disc;
    li {
      list-style-type: disc;
      margin-bottom: 1rem;
    }
  }
`;
