import React from 'react'
import styled from 'styled-components'
import { Color } from '../../typescript/types'
import Img, { FluidObject } from 'gatsby-image'
import { darken } from 'polished'

interface Props {
  imgSrc: FluidObject
  quote: string
  author: string
  bg?: Color
  style?: any
}

const QuoteBubble: React.FC<Props> = ({ imgSrc, quote, author, bg, ...rest }) => {
  return (
    <Bubble bg={bg} {...rest}>
      <ImgWrapper>
        <Img fluid={imgSrc} alt={author} />
      </ImgWrapper>
      <QuoteWrapper>
        <Quote>{quote}</Quote>
        <Author color={bg}>{author}</Author>
      </QuoteWrapper>
      <QuotationMark color={bg}>‘‘</QuotationMark>
    </Bubble>
  )
}

const Bubble = styled.div<{ bg?: Color }>`
  background: ${({ bg, theme }) => (bg ? theme.colors[bg] : theme.colors.grey300)};
  display: grid;
  grid-template-columns: min-content 1fr;
  padding: 1rem;
  border-radius: 1rem;
  gap: 2rem;
  position: relative;
  margin-top: 3rem;
  height: fit-content;
  transition: box-shadow 0.15s ease;

  &:hover {
    box-shadow: ${({ theme }) => theme.boxShadow.small};
  }

  @media all and (max-width: 48em) {
    grid-template-columns: 1fr;
  }
`

const QuoteWrapper = styled.div`
  display: grid;
  gap: 1rem;
  font-weight: 500;
`

const Author = styled.p<{ color?: Color }>`
  font-weight: 600;
  color: ${({ color, theme }) => (color ? darken(0.6, theme.colors[color]) : theme.colors.grey300)};
`

const ImgWrapper = styled.div`
  --size: 124px;
  border-radius: 50%;
  width: var(--size);
  height: var(--size);
  overflow: hidden;
`

const Quote = styled.blockquote`
  font-weight: 500;
`

const QuotationMark = styled.span<{ color?: Color }>`
  position: absolute;
  top: 12px;
  font-size: 124px;
  line-height: 0;
  z-index: -1;
  font-family: 'Times New Roman';
  letter-spacing: -12px;
  color: ${({ color, theme }) => (color ? darken(0.1, theme.colors[color]) : theme.colors.grey300)};
`

export default QuoteBubble
