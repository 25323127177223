import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

interface Props {
  to: string;
  internal?: boolean;
}

export default ({ children, to, internal }: PropsWithChildren<Props>) =>
  internal ? <StyledLink to={to}>{children}</StyledLink> : <A href={to}>{children}</A>;

const StyledLink = styled(Link)`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.green} !important;
  font-size: 1.125rem;
  line-height: 1.8;

  @media all and (max-width: 26.563em) {
    font-size: 1rem;
  }
`;

const A = styled.a`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.green} !important;
  font-size: 1.125rem;
  line-height: 1.8;

  @media all and (max-width: 26.563em) {
    font-size: 1rem;
  }
`;
