import { FluidObject } from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'
import { points as defaultPoints } from '../../../constants/points'
import Points from '../../Points'
import getFluidOrSvg from '../../utils/getFluidOrSvg'

interface Props {
  imgSrc: {
    childImageSharp: {
      fluid: FluidObject
    }
    extension: string
    publicURL: string
  }
  alt: string
  points?: string[]
  withPoints?: boolean
}

export default ({ imgSrc, alt, points, withPoints = true }: Props) => {
  return (
    <Wrapper>
      {getFluidOrSvg({ imgSrc, alt })}
      {withPoints && (
        <PointsWrapper>
          <Points points={points ?? defaultPoints} quantity={3} />
        </PointsWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  border-radius: 16px;
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.boxShadow.small};
  display: grid;
`

const PointsWrapper = styled.div`
  background: #fff;
  padding: 1.5rem;
  display: grid;
  gap: 1rem;
`
