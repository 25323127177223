import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

export default ({ children }: PropsWithChildren<{}>) => <P>{children}</P>;

const P = styled.p`
  font-size: 1.125rem;
  line-height: 1.8;

  @media all and (max-width: 26.563em) {
    font-size: 1rem;
  }
`;
