import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

interface Props {
  align: 'auto' | 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'initial' | 'inherit';
}

export default ({ children, align }: PropsWithChildren<Props>) => <ImgWrapper align={align}>{children}</ImgWrapper>;

const ImgWrapper = styled.div<Props>`
  align-self: ${({ align }) => align || 'auto'};
`;
