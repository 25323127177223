
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

interface Props {
  citation: number;
}

export default ({ citation }: PropsWithChildren<Props>) => <Citation>
<a href="#citations">[{citation}]</a></Citation>;

const Citation = styled.sub`
  color: ${({ theme }) => theme.colors.purple} !important;
  font-weight: 600;

  a {
    color: ${({ theme }) => theme.colors.purple} !important;
  }
`;