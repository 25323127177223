import React from 'react'
import styled from 'styled-components'
import Form from '../../Form'

interface Props {
  cta: string
  buttonAction?: string
  formId?: 'start-a-demo' | 'contact'
}

export default ({ cta, buttonAction, formId }: Props) => {
  return (
    <HeroCta>
      <H3>{cta}</H3>
      <Form
        whitefields
        formName={formId ?? 'start-a-demo'}
        successMessage='Thank you, we will be in touch with you soon'
        errorMessage='Oops, something went wrong - please try again'
        action={buttonAction ?? 'Start Demo'}
      />
    </HeroCta>
  )
}

const HeroCta = styled.div`
  display: grid;
  gap: 3rem;
`

const H3 = styled.h3`
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: -0.5px;
`
