import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

interface Props {
  noPadding?: boolean;
}

export default ({ children, noPadding }: PropsWithChildren<Props>) => <Main noPadding={noPadding}>{children}</Main>;

const Main = styled.main<Props>`
  padding-bottom: ${({ noPadding }) => (noPadding ? '0' : '6rem')};
`;
