import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import Container from '../../Container';

interface Citation {
  id: number;
  link: string;
  title: string;
}

interface Props {
  citations: Citation[];
}

export default ({ citations }: Props) => (
  <Citations>
    <Container>
      <CitationsInner id="citations">
        <H3>Citations</H3>
        <CitationsInnerList>
          {citations.map((el) => (
            <CitationsInnerItem key={el.id}>
              <span>{el.id}</span>
              <a href={el.link} target="_blank" rel="noreferrer">
                <Title>{el.title}</Title>
              </a>
            </CitationsInnerItem>
          ))}
        </CitationsInnerList>
      </CitationsInner>
    </Container>
  </Citations>
);

const Citations = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.grey300};
`;

const Title = styled.span`
  font-weight: 500;
  text-decoration: underline;
  transition: all 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.blue};
  }
`;

const H3 = styled.h3`
  margin: 0;
  font-weight: 500;
  font-size: 2.25rem;
  letter-spacing: -1px;
  line-height: 1.3;
  text-transform: capitalize;
  position: relative;
  padding-top: 1.5rem;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    background: ${({ theme }) => theme.colors.purple};
    height: 4px;
    width: 3rem;
  }

  @media all and (max-width: 28.125em) {
    font-size: 1.5rem;
  }
`;

const CitationsInner = styled.div`
  padding: 6rem 3rem;
`;

const CitationsInnerList = styled.ul`
  display: grid;
  gap: 1.5rem;
  padding-top: 3rem;
`;

const CitationsInnerItem = styled.li`
  display: grid;
  grid-template-columns: 0.1fr 1fr;
  gap: 1.5rem;
`;
