import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

export default ({ children }: PropsWithChildren<{}>) => <Strong>{children}</Strong>;

const Strong = styled.strong`
  font-size: 1.125rem;
  line-height: 1.8;
  color: ${({ theme }) => theme.colors.purple};
  position: relative;

  @media all and (max-width: 26.563em) {
    font-size: 1rem;
  }

  &:before {
    position: absolute;
    content: '';
    top: 0;
    margin-left: -1rem;
    left: 0;
    width: 4px;
    height: 100%;
    background: ${({ theme }) => theme.colors.purple};
  }
`;
