import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Color } from '../../typescript/types';

interface Props {
  children: ReactNode;
  bgColor: Color;
}

export default ({ children, bgColor }: Props) => <Intro bgColor={bgColor}>{children}</Intro>;

interface IntroProps {
  bgColor: Color;
}

const Intro = styled.div<IntroProps>`
  display: grid;
  gap: 3rem;
  align-items: center;
  justify-content: center;
  height: fit-content;
  padding: 9rem 0 3rem;
  background: ${({ bgColor, theme }) => theme.colors[bgColor]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey300};
  margin-bottom: 3rem;
`;
