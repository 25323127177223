import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

export default ({ children }: PropsWithChildren<{}>) => <H2>{children}</H2>;

const H2 = styled.h2`
  margin: 0;
  font-weight: 400;
  font-size: 1rem;
  max-width: 50ch;
  line-height: 1.7;

  @media all and (max-width: 48em) {
    font-size: 1rem;
  }
`;
