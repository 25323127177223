import React from 'react';
import { Clock } from 'react-feather';
import styled from 'styled-components';
import { format } from 'date-fns'

interface Props {
  readingTime: string;
  date: Date;
}

export default ({ readingTime, date }: Props) => (
  <DateAndReadingTime>
    <ReadingTime>
      <Clock size={16} />
      <StyledDate>{readingTime}</StyledDate>
    </ReadingTime>
    <Spacer>·</Spacer>
    <StyledDate>{format(new Date(date),'LLL dd, yyyy')}</StyledDate>
  </DateAndReadingTime>
);

const DateAndReadingTime = styled.div`
  display: grid;
  gap: 0.5rem;
  width: fit-content;
  justify-content: center;
  grid-auto-flow: column;
  margin: 0 auto;
`;

const StyledDate = styled.h4`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.grey500};
  font-size: 0.875rem;
`;

const ReadingTime = styled.time`
  display: grid;
  gap: 0.5rem;
  grid-auto-flow: column;
  width: fit-content;
  align-items: center;

  svg {
    stroke: ${({ theme }) => theme.colors.grey500};
  }
`;

const Spacer = styled.span`
  color: ${({ theme }) => theme.colors.grey700};
`;
